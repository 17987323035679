var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"p-5 bg-white rounded-lg"},[_c('h1',{staticClass:"my-5 text-xl text-center"},[_vm._v("服務商預設歡迎訊息樣板")]),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"content"}},[_c('el-input',{attrs:{"type":"textarea","rows":"10"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1)],1),_c('div',{staticClass:"flex justify-center my-5"},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['create', 'update']),expression:"['create', 'update']"}],staticClass:"mb-10",attrs:{"type":"primary","native-type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit('form')}}},[_vm._v(" 更新 ")])],1)],1),_c('div',{staticClass:"p-2 px-5 my-1 bg-white rounded-lg"},[_c('div',{staticClass:"flex items-center"},[_c('h1',{staticClass:"flex-grow my-5 text-xl"},[_vm._v("服務商聊天室回應訊息樣板")]),_c('button',{directives:[{name:"permission",rawName:"v-permission",value:(['create']),expression:"['create']"}],staticClass:"blue-btn",on:{"click":function($event){$event.preventDefault();return _vm.addColumn.apply(null, arguments)}}},[_vm._v(" 新增 ")])]),_c('el-form',{ref:"formChat",attrs:{"model":_vm.formChat,"rules":_vm.rulesChat}},[_c('table',{staticClass:"myTable"},[_c('thead',[_c('tr',[_c('th',[_vm._v("編號")]),_c('th',[_vm._v("內容")])])]),(Object.keys(_vm.formChat).length > 0)?_c('tbody',_vm._l((_vm.formChat),function(data,key){return _c('tr',{key:key},[_c('td',{staticClass:"w-[50px] text-center cursor-pointer",on:{"click":function($event){return _vm.dropColmun(key)}}},[_vm._v(" "+_vm._s(key)+" "),_c('i',{staticClass:"text-2xl text-orange-600 icofont-ui-delete"})]),_c('td',_vm._l((_vm.formChat[key][
                                    'description'
                                ]),function(value,key2){return _c('el-form-item',{key:key2,staticClass:"mb-0",attrs:{"prop":`${key}.description.${key2}`}},[_c('label',{staticClass:"mb-0 leading-none"},[_vm._v(" "+_vm._s(key2)+" "),_c('el-input',{model:{value:(
                                            _vm.formChat[key]['description'][
                                                key2
                                            ]
                                        ),callback:function ($$v) {_vm.$set(_vm.formChat[key]['description'], 
                                                key2
                                            , $$v)},expression:"\n                                            formChat[key]['description'][\n                                                key2\n                                            ]\n                                        "}})],1)])}),1)])}),0):_vm._e()])]),_c('div',{staticClass:"flex justify-center my-5"},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['create', 'update']),expression:"['create', 'update']"}],staticClass:"mb-10",attrs:{"type":"primary","native-type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmitFormChat('formChat')}}},[_vm._v(" 更新 ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }