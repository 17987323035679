<template>
    <div>
        <div class="p-5 bg-white rounded-lg">
            <h1 class="my-5 text-xl text-center">服務商預設歡迎訊息樣板</h1>
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item prop="content">
                    <el-input
                        v-model="form.content"
                        type="textarea"
                        rows="10"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="flex justify-center my-5">
                <el-button
                    v-permission="['create', 'update']"
                    class="mb-10"
                    type="primary"
                    native-type="submit"
                    @click.prevent="onSubmit('form')"
                >
                    更新
                </el-button>
            </div>
        </div>
        <div class="p-2 px-5 my-1 bg-white rounded-lg">
            <div class="flex items-center">
                <h1 class="flex-grow my-5 text-xl">服務商聊天室回應訊息樣板</h1>
                <button
                    v-permission="['create']"
                    class="blue-btn"
                    @click.prevent="addColumn"
                >
                    新增
                </button>
            </div>
            <el-form ref="formChat" :model="formChat" :rules="rulesChat">
                <table class="myTable">
                    <thead>
                        <tr>
                            <th>編號</th>
                            <th>內容</th>
                        </tr>
                    </thead>
                    <tbody v-if="Object.keys(formChat).length > 0">
                        <tr v-for="(data, key) in formChat" :key="key">
                            <td
                                class="w-[50px] text-center cursor-pointer"
                                @click="dropColmun(key)"
                            >
                                {{ key }}
                                <i
                                    class="text-2xl text-orange-600 icofont-ui-delete"
                                ></i>
                            </td>
                            <td>
                                <el-form-item
                                    v-for="(value, key2) in formChat[key][
                                        'description'
                                    ]"
                                    :key="key2"
                                    class="mb-0"
                                    :prop="`${key}.description.${key2}`"
                                >
                                    <label class="mb-0 leading-none">
                                        {{ key2 }}
                                        <el-input
                                            v-model="
                                                formChat[key]['description'][
                                                    key2
                                                ]
                                            "
                                        ></el-input>
                                    </label>
                                </el-form-item>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </el-form>
            <div class="flex justify-center my-5">
                <el-button
                    v-permission="['create', 'update']"
                    class="mb-10"
                    type="primary"
                    native-type="submit"
                    @click.prevent="onSubmitFormChat('formChat')"
                >
                    更新
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase/app";
import { firebaseConnectRef } from "@/plugins/firebase";
export default {
    data() {
        return {
            form: {
                content: "",
            },
            rules: {
                content: [
                    {
                        required: true,
                        message: "請輸入更新內容",
                        trigger: "blur",
                    },
                ],
            },
            formChat: {},
            rulesChat: {},
        };
    },
    methods: {
        // 取得註冊發送訊息資料
        async getData() {
            try {
                const data = await firebaseConnectRef(
                    "provider_default_message"
                ).get();
                // 有資料在更新表單內容
                if (data.exists()) {
                    this.$set(this.form, "content", data.val().content);
                }
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                await this.update();
                await this.getData();
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 更新服務商預設樣板發送訊息資料
        async update() {
            try {
                await firebaseConnectRef("provider_default_message").set({
                    ...this.form,
                    updatedAt: firebase.database.ServerValue.TIMESTAMP,
                });
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        },
        // 新增欄位
        async addColumn() {
            // 預設表單資料
            function setDefaultData() {
                this.description = {
                    tw: "",
                    en: "",
                };
            }
            const defaultData = new setDefaultData();
            try {
                const newKey = await firebase
                    .database()
                    .ref("provider_default_message/chats")
                    .push().key;
                this.$set(this.formChat, newKey, defaultData);
                this.$set(this.rules, `${this.newKey}.description.tw`, [
                    {
                        required: true,
                        message: "中文描述為必填",
                        trigger: "blur",
                    },
                ]);
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "新增服務商聊天室樣板訊息key失敗",
                });
            }
        },
        /**
         * 刪除欄位
         * @param { type String(字串) } key 刪除key
         */
        async dropColmun(key) {
            try {
                await this.$confirm(`刪除此訊息樣板${key}?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                this.$delete(this.formChat, key);
                firebase
                    .database()
                    .ref("provider_default_message/chats/" + key)
                    .remove();
                this.$message({
                    type: "success",
                    message: "刪除成功",
                });
            } catch (err) {
                console.log(err);
            }
        },
        // 更新服務商預設聊天室樣板發送訊息資料
        async updateFormChat() {
            try {
                await firebaseConnectRef("provider_default_message/chats").set({
                    ...this.formChat,
                });
                console.log(this.formChat);
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmitFormChat(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                await this.updateFormChat();
                await this.getList();
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 取得列表
        async getList() {
            try {
                const data = await firebase
                    .database()
                    .ref("provider_default_message/chats")
                    .get();
                // 判斷是否有訊息
                if (data.exists()) {
                    this.formChat = {};
                    console.log(data.val());
                    this.setDefault(data.val());
                }
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得數據失敗",
                });
            }
        },
        // 設定表單資料
        setDefault(datas) {
            Object.keys(datas).forEach((objKey) => {
                this.$set(this.formChat, objKey, {
                    description: datas[objKey].description,
                });
                Object.keys(this.formChat[objKey]["description"]).forEach(
                    () => {
                        this.$set(this.rules, `${objKey}.description.tw`, [
                            {
                                required: true,
                                message: "中文描述為必填",
                                trigger: "blur",
                            },
                        ]);
                        this.$set(this.formChat[objKey], "description", {
                            tw: this.formChat[objKey]["description"]["tw"],
                            en: this.formChat[objKey]["description"]["en"],
                        });
                    }
                );
            });
        },
    },
    async created() {
        await this.getData();
        await this.getList();
    },
};
</script>
i
